module.exports = {
    1: "mainnet",
    3: "ropsten",
    4: "rinkeby",
    5: "goerli",
    42: "kovan",
    137: "matic",
    42161: "arbone",
    80001: "mumbai",
    11155111: "sepolia"
}